import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./store";
import "./index.css";
import "./bootstrap.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/Header";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BUGSNAG_KEY } from "./api_service";

Bugsnag.start({
	apiKey: BUGSNAG_KEY,
	plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<BrowserRouter>
				<Header />
				<App />
			</BrowserRouter>
		</Provider>
	</ErrorBoundary>,
	document.getElementById("root")
);

reportWebVitals();
