import React from "react";
import { Route, Routes } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import "./sigCanvas.css";

import RegisterVisitor from "./screens/RegisterVisitor";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import SuccessScreen from "./screens/SuccessScreen";

function App() {

	return (
		<>
			<Container>
				<Routes>
					<Route path="/" element={<HomeScreen />} />
					{/* Login as admin / seller */}
					<Route path="/login" element={<LoginScreen />} />
					<Route path="/register" element={<RegisterVisitor />} />
					{/* QR-Scan */}
					<Route path="/register/:id/:key" element={<RegisterVisitor />} />
					<Route path="/success" element={<SuccessScreen />} />
				</Routes>
			</Container>
		</>
	);
}

export default App;
