import React from "react";
import { Spinner } from "react-bootstrap";

function Loader() {
	return (
		<React.Fragment>
			<Spinner
				animation="border"
				role="status"
				style={{
					height: "100px",
					width: "100px",
					margin: "auto",
					display: "block",
				}}
			></Spinner>
			<span className="sr-only">Loading...</span>
		</React.Fragment>
	);
}

export default Loader;
