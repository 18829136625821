import axios from "axios";
import {
	ACCOUNT_DETAILS_REQUEST,
	ACCOUNT_DETAILS_SUCCESS,
	ACCOUNT_DETAILS_FAIL,
} from "../constants/accountConstants";

import { API_URL } from "../api_service";

export const accountDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACCOUNT_DETAILS_REQUEST,
		});

		const { data } = await axios.get(`${API_URL}/accounts_api/account_visitor/${id}/`);

		dispatch({
			type: ACCOUNT_DETAILS_SUCCESS,
			payload: data,
		});

	} catch (error) {
		dispatch({
			type: ACCOUNT_DETAILS_FAIL,
			payload:
				error.response && error.response.data.detail
					? error.response.data.detail
					: error.message,
		});
	}
};

