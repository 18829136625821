import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import { Card, Button, Form, Container, ButtonGroup } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { accountDetails } from "../actions/accountActions";
import DOMPurify from "dompurify";
import "../sigCanvas.css";

import { API_URL } from "../api_service";

function RegisterVisitor() {
	const [qrMode, setQRMode] = useState(false);
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [agree, setAgree] = useState("");
	const params = useParams();

	const accountId = params.id;
	const accountKey = params.key;

	const [showModal, setShowModal] = useState(false);

	const [error, setError] = useState("");

	const sigCanvas = useRef({});

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const accountDetail = useSelector((state) => state.accountDetails);
	const { account } = accountDetail;

	useEffect(() => {
		if (accountId && account && !account.id) {
			dispatch(accountDetails(accountId));
		}
		if (!accountId && account && !account.id) {
			navigate("/login");
		}
		if (userInfo === null) {
			setQRMode(true);
		}
	}, [account, navigate, accountId, accountKey, dispatch, userInfo]);

	/* a function that uses the canvas ref to clear the canvas 
  	via a method given by react-signature-canvas */
	const clear = () => sigCanvas.current.clear();

	/* a function that uses the canvas ref to trim the canvas 
  	from white spaces via a method given by react-signature-canvas
  	then saves it in our state */
	const save = () => {
		setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
		setShowModal(false);
	};

	const closeModal = () => setShowModal(false);

	const submitHandler = (e) => {
		e.preventDefault();

		const visitor = {
			account: account.id,
			first_name: firstName,
			last_name: lastName,
			email: email,
			address: address,
			city: city,
			postalCode: postalCode,
			signature: imageURL,
			regKey: accountKey,
			qr_mode: qrMode,
		};

		const createVisitor = async () => {
			axios
				.post(`${API_URL}/rent_api/register_visitor/`, visitor)
				.then((response) => {
					setFirstName("");
					setLastName("");
					setEmail("");
					setAddress("");
					setCity("");
					setPostalCode("");
					setImageURL("");
					navigate("/success");
				})
				.catch((reason) => {
					setError(reason.response.data.detail);
				});
		};

		createVisitor();
	};

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	if (showDisclaimer) {
		return (
			<Container>
				<Button onClick={() => setShowDisclaimer(false)} className="mt-4 mb-4">
					zurück
				</Button>{" "}
				<div dangerouslySetInnerHTML={createMarkup(account.disclaimer)}></div>
			</Container>
		);
	}

	return (
		<Container>
			<div className="App">
				{error && (
					<React.Fragment>
						<br />
						<Message variant="error">{error}</Message>
					</React.Fragment>
				)}
				{account && account.id ? (
					<>
						<br />
						<h4>{account.name} - Kunden Registrierung</h4>
						{!showModal && (
							<React.Fragment>
								<Card className="p-4">
									<Form onSubmit={submitHandler}>
										<Form.Group controlId="firstName">
											<Form.Label>Vorname*</Form.Label>
											<Form.Control
												required
												type="name"
												placeholder="Vorname"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												autoFocus
												// autoComplete="no"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="lastName" className="py-3">
											<Form.Label>Nachname*</Form.Label>
											<Form.Control
												required
												type="name"
												placeholder="Nachname"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												// autoComplete="no"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="email">
											<Form.Label>E-Mail*</Form.Label>
											<Form.Control
												required
												type="email"
												placeholder="Deine E-Mail Adresse"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												// autoComplete="no"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="adress" className="py-3">
											<Form.Label>Straße und Hausnummer*</Form.Label>
											<Form.Control
												required
												type="text"
												placeholder="Adresse"
												value={address ? address : ""}
												onChange={(e) => setAddress(e.target.value)}
												// autoComplete="no"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="adress">
											<Form.Label>Postleitzahl*</Form.Label>
											<Form.Control
												required
												type="text"
												placeholder="Postleitzahl"
												value={postalCode ? postalCode : ""}
												onChange={(e) => setPostalCode(e.target.value)}
												// autoComplete="no"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="adress" className="py-3">
											<Form.Label>Stadt*</Form.Label>
											<Form.Control
												required
												type="text"
												placeholder="Adresse"
												value={city ? city : ""}
												onChange={(e) => setCity(e.target.value)}
												// autoComplete="off"
											></Form.Control>
										</Form.Group>

										<Form.Group controlId="confirmAGB" className="pb-3">
											<Form.Check
												required
												checked={agree}
												onChange={(e) => setAgree(!agree)}
												type="checkbox"
												label="Ja, ich stimme den Allgemeinen Geschäftsbedingungen zu und habe die Datenschutzbestimmungen zur Kenntnis genommen.*"
											></Form.Check>
										</Form.Group>

										<Button
											variant="outline-primary"
											onClick={() => setShowModal(true)}
										>
											Zum Unterschreiben hier klicken
										</Button>
										<br />
										<br />

										{imageURL ? (
											<img
												src={imageURL}
												alt="Unterschrift"
												style={{
													margin: "0 auto",
													// border: "1px solid gray",
													borderRadius: "5px",
													width: "150px",
												}}
											/>
										) : null}

										<br />
										<br />
										<Button
											type="submit"
											variant="primary"
											className="mt-3 mb-3"
											disabled={!imageURL}
										>
											Check In
										</Button>
									</Form>
								</Card>
								<br />
								<Button onClick={() => setShowDisclaimer(true)}>
									AGB | Haftungsausschluss anzeigen
								</Button>
								<br />
								<br />
								<a
									href="https://booking.yuuplace.com/privacy"
									target="blank"
								>
									Datenschutzerklärung
								</a>
								<br />
							</React.Fragment>
						)}
						<Popup
							modal
							open={showModal}
							closeOnDocumentClick={false}
							onClose={closeModal}
						>
							<Container>
								<SignaturePad
									ref={sigCanvas}
									canvasProps={{
										className: "signatureCanvas",
									}}
								/>
								{/* Button to trigger save canvas image */}
								<ButtonGroup>
									<Button variant="outline-primary" onClick={save}>
										speichern
									</Button>
									<Button variant="outline-primary" onClick={clear}>
										löschen
									</Button>
									<Button variant="outline-primary" onClick={closeModal}>
										schließen
									</Button>
								</ButtonGroup>
							</Container>
						</Popup>
					</>
				) : (
					<Loader />
				)}
				<br />
			</div>
		</Container>
	);
}

export default RegisterVisitor;
