export const ACCOUNT_DETAILS_REQUEST = 'ACCOUNT_DETAILS_REQUEST'
export const ACCOUNT_DETAILS_SUCCESS = 'ACCOUNT_DETAILS_SUCCESS'
export const ACCOUNT_DETAILS_FAIL = 'ACCOUNT_DETAILS_FAIL'
export const ACCOUNT_DETAILS_RESET = 'ACCOUNT_DETAILS_RESET'

export const USER_ACCOUNT_DETAILS_REQUEST = 'USER_ACCOUNT_DETAILS_REQUEST'
export const USER_ACCOUNT_DETAILS_SUCCESS = 'USER_ACCOUNT_DETAILS_SUCCESS'
export const USER_ACCOUNT_DETAILS_FAIL = 'USER_ACCOUNT_DETAILS_FAIL'
export const USER_ACCOUNT_DETAILS_RESET = 'USER_ACCOUNT_DETAILS_RESET'

export const MESSAGE_SEND_REQUEST = 'MESSAGE_SEND_REQUEST'
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS'
export const MESSAGE_SEND_FAIL = 'MESSAGE_SEND_FAIL'
export const MESSAGE_SEND_RESET = 'MESSAGE_SEND_RESET'

