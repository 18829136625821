import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { logout } from "../actions/userActions";

import { API_URL } from "../api_service";

function Header() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const accountDetail = useSelector((state) => state.accountDetails);
	const { account: userAccount } = accountDetail;

	const logoutHandler = () => {
		dispatch(logout());
		navigate("/login")
	};

	return (
		<React.Fragment>
			<header>
				<Navbar variant="light" expand="lg" collapseOnSelect>
					<Container>
						{userAccount && (
							<Navbar.Brand>
								<Link
									className="navbar-brand py-0 my-0"
									to={`/register`}
								>
									<Image
										src={`${API_URL}${userAccount.logo}`}
										style={{ maxHeight: "40px" }}
									/>
								</Link>
							</Navbar.Brand>
						)}

						<Nav className="me-auto" style={{}}>
							{userInfo ? (
								<Nav.Item
									className="nav-link"
									onClick={logoutHandler}
									style={{ cursor: "pointer", textAlign: "center" }}
								>
									<i className="fas fa-sign-out-alt"></i>
									<p style={{ fontSize: "8px", margin: "0" }}>LOGOUT</p>
								</Nav.Item>
							) : (
								<Nav.Item
									className="nav-link"
									onClick={() => navigate('/login')}
									style={{ cursor: "pointer", textAlign: "center" }}
								>
									<i className="fas fa-sign-out-alt"></i>
									<p style={{ fontSize: "8px", margin: "0" }}>LOGIN</p>
								</Nav.Item>
							)}
						</Nav>
					</Container>
				</Navbar>
			</header>
		</React.Fragment>
	);
}

export default Header;
