import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { login } from "../actions/userActions";
import { accountDetails } from "../actions/accountActions";

function LoginScreen() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const userLogin = useSelector((state) => state.userLogin);
	const { error, loading, userInfo } = userLogin;

	const accountDetail = useSelector((state) => state.accountDetails);
	const { account } = accountDetail;

	useEffect(() => {
		if (userInfo && account && !account.id) {
			dispatch(accountDetails(userInfo.rental.id));
		}
		if (userInfo && account && account.id) {
			navigate("/register");
		}
	}, [navigate, dispatch, account, userInfo, loading]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login(email, password));
	};

	return (
		<FormContainer>
			<br />
			<h4>Einloggen</h4>
			{error && <Message variant="error">{error}</Message>}
			{loading && <Loader />}
			<Form onSubmit={submitHandler}>
				<Form.Group controlId="email">
					<Form.Label>E-Mail</Form.Label>
					<Form.Control
						type="email"
						placeholder="Deine E-Mail Adresse"
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>
				<Form.Group controlId="password" className="py-3">
					<Form.Label>Passwort</Form.Label>
					<Form.Control
						type="password"
						autoComplete="false"
						placeholder="Deine Passwort"
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>
				<Button type="submit" variant="primary">
					Sign In
				</Button>
			</Form>
		</FormContainer>
	);
}

export default LoginScreen;
