import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../animation.css";
import { ACCOUNT_DETAILS_RESET } from "../constants/accountConstants";

const SuccessScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		if (!userInfo) {
			dispatch({ type: ACCOUNT_DETAILS_RESET });
		}
	}, [userInfo, dispatch]);

	return (
		<Container>
			<br />
			<div
				className="swal2-icon swal2-success swal2-animate-success-icon"
				style={{ display: "flex" }}
			>
				<div className="swal2-success-circular-line-left"></div>
				<span className="swal2-success-line-tip"></span>
				<span className="swal2-success-line-long"></span>
				<div className="swal2-success-ring"></div>
				<div className="swal2-success-fix"></div>
				<div className="swal2-success-circular-line-right"></div>
			</div>
			<br />
			{userInfo && (
				<div style={{ textAlign: "center" }}>
					<Button onClick={() => navigate("/register")}>Fertig</Button>
				</div>
			)}
		</Container>
	);
};

export default SuccessScreen;
