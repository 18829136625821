import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const HomeScreen = () => {

	const navigate = useNavigate();

	const params = useParams();

	const accountId = params.id;
	const accountKey = params.key;

	useEffect(() => {
		if (accountId && accountKey) {
			navigate(`/register/${accountId}/${accountKey}`);
		} else {
			navigate('/login');
		}
	}, [navigate, accountKey, accountId]);


    return <div></div>

};

export default HomeScreen;
