import { 
    ACCOUNT_DETAILS_REQUEST, ACCOUNT_DETAILS_SUCCESS, ACCOUNT_DETAILS_FAIL, ACCOUNT_DETAILS_RESET, 
} from '../constants/accountConstants'

export const accountDetailsReducer = ( state = { account: [] }, action) => {
    switch(action.type){
        case ACCOUNT_DETAILS_REQUEST :
            return{loading:true }

        case ACCOUNT_DETAILS_SUCCESS :
            return{loading:false, account: action.payload}

        case ACCOUNT_DETAILS_FAIL :
            return{loading:false, error: action.payload}

        case ACCOUNT_DETAILS_RESET :
            return{ account: [] }

        default:
            return state
    }
}

